<template>
  <div>
    <a-modal
      v-model="visibleModalManagePengajuanLembur"
      class="mod"
      :title="status.isEdit ? 'Form Ubah Pengajuan Lembur' : 'Form Tambah Pengajuan Lembur'"
      @cancel="toggleModalManageLembur"
      centered
      :destroyOnClose="true"
    >
      <form action="" enctype="multipart/form-data">
        <form-pengajuan-lembur
          :allList="allIzin"
          statusPengajuan="lembur"
          :disabledForm="newPengajuanLembur.status_pengajuan === 'terima' || newPengajuanLembur.status_pengajuan === 'tolak' ? true : false"
          :dataPengajuan="newPengajuanLembur"
          @handle-change="handleChange"
        />
      </form>
      <template slot="footer">
        <a-button :class="newPengajuanLembur.status_pengajuan === 'terima' || newPengajuanLembur.status_pengajuan === 'tolak' ? 'd-none' : ''" size="large" key="back" @click="toggleModalManageLembur">
          Batal
        </a-button>
        <a-button :class="newPengajuanLembur.status_pengajuan === 'terima' || newPengajuanLembur.status_pengajuan === 'tolak' ? 'd-none' : ''" size="large" key="submit" type="primary" :loading="loadingActionModalManagePengajuanLembur" @click="handleOkModalManagePengajuanLembur">
          {{status.isEdit ? 'Ubah' : 'Tambah'}} Sekarang
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
        <div class="ml-auto">
          <a-button @click.prevent="toggleModalManageLembur('add')" size="large" type="primary"><a-icon type="plus" />Tambah Pengajuan Lembur</a-button>
        </div>
      </div>
      <div class="mt-4">
        <a-table
          :columns="pengajuanLemburColumns"
          :data-source="pengajuanLemburDataTable"
          :pagination="pengajuanLemburPagination"
          :loading="pengajuanLemburLoadingTable"
          bordered
        >
          <div class="d-flex flex-column" slot="nama" slot-scope="value, record">
            <span class="mb-3 font-weight-bold">{{value}}</span>
            <span class="mb-1">Diajukan pada:</span>
            <span class="">({{moment(record.createdAt).format('DD MMM YYYY HH:mm')}})</span>
            <!-- <span>
              <a-tag :color="record.status_pengajuan === 'menunggu' ? 'orange' : record.status_pengajuan === 'tolak' ? 'red' : 'green'">
              {{record.status_pengajuan === 'terima' ? 'DITERIMA' : record.status_pengajuan.toUpperCase()}}
            </a-tag>
            </span> -->
        </div>
          <span slot="status_pengajuan" slot-scope="value">
            <a-tag :color="value === 'menunggu' ? 'orange' : value === 'tolak' ? 'red' : 'green'">
            {{value === 'terima' ? 'DITERIMA' : value === 'tolak' ? 'DITOLAK' : value.toUpperCase() + ' PERSETUJUAN ADMIN'}}
          </a-tag>
          </span>
          <div slot="regulasi" slot-scope="value">
            <a-tag :color="value === 'Tidak Dibayar' ? 'red' : 'green'">
              {{value.toUpperCase()}}
            </a-tag>
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center" slot="aksi" slot-scope="value, record">
            <a-button
              @click.prevent="toggleModalManageLembur('edit', record)"
              class="w-100 mb-3 text-warning border border-warning"
              size="large"
            >
              <a-icon :type="record.status_pengajuan === 'terima' || record.status_pengajuan === 'tolak' ? 'container' : 'edit'"/>{{record.status_pengajuan === 'terima' || record.status_pengajuan === 'tolak' ? 'Detail' : 'Ubah'}}
            </a-button>
            <a-button
              :disabled="record.status_pengajuan === 'terima'"
              @click.prevent="handleDeletePengajuanLembur(record)"
              class="w-100 text-danger border border-danger"
              size="large"
            >
              <a-icon type="delete" />Hapus
            </a-button>
          </div>
        </a-table>
      </div>
  </div>
</template>

<script>
import moment from 'moment'
import config from '@/config'
import axios from 'axios'
const FormPengajuanLembur = () => import('@/components/app/Pegawai/FormPengajuan')

const pengajuanLemburColumns = [
  {
    title: 'Keterangan',
    dataIndex: 'keterangan_tambahan',
    key: 'keterangan_tambahan',
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Periode',
    dataIndex: 'tahun',
    align: 'center',
    children: [
      {
        title: 'Waktu Mulai',
        dataIndex: 'waktu_awal',
        key: 'waktu_awal',
        // width: 200,
        align: 'center',
        scopedSlots: { customRender: 'waktu_awal' },
      },
      {
        title: 'Waktu Selesai',
        dataIndex: 'waktu_akhir',
        key: 'waktu_akhir',
        // width: 200,
        align: 'center',
        scopedSlots: { customRender: 'waktu_akhir' },
      },
    ],
  },
  {
    title: 'Status Pengajuan',
    dataIndex: 'status_pengajuan',
    key: 'status_pengajuan',
    align: 'center',
    scopedSlots: { customRender: 'status_pengajuan' },
  },
  {
    title: 'Aksi',
    dataIndex: 'aksi',
    key: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    align: 'center',
  },
]
export default {
  name: 'PengajuanLembur',
  components: {
    FormPengajuanLembur,
  },
  data() {
    return {
      pengajuanLemburColumns,
      visibleModalManagePengajuanLembur: false,
      loadingActionModalManagePengajuanLembur: false,
      loadingDeletePengajuanLembur: false,
      pengajuanLemburLoadingTable: false,
      pengajuanLemburDataTable: [],
      allIzin: [],
      pengajuanLemburPagination: {},
      status: {
        isEdit: false,
        isCreate: false,
      },
      newPengajuanLembur: {
        id_pegawai: null,
        waktu_awal: null,
        waktu_akhir: null,
        keterangan_tambahan: null,
        files_upload: [],
        files: [],
        status_pengajuan: null,
      },
      idEdit: null,
    }
  },
  methods: {
    moment,
    handleChange(payload) {
      const { value, column } = payload

      this.newPengajuanLembur[column] = value
    },
    toggleModalManageLembur(status, data) {
      this.visibleModalManagePengajuanLembur = !this.visibleModalManagePengajuanLembur
      if (!this.visibleModalManagePengajuanLembur) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newPengajuanLembur = {
            id_pegawai: null,
            waktu_awal: null,
            waktu_akhir: null,
            keterangan_tambahan: null,
            files_upload: [],
            files: [],
            status_pengajuan: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newPengajuanLembur = {
            ...data,
            waktu_awal: moment(data.waktu_awal),
            waktu_akhir: moment(data.waktu_akhir),
            files: data.files,
            files_upload: [],
          }
        }
      }
    },
    periodeIsExist() {
      this.fetchDataPengajuanLembur()
      const isExist = Boolean(this.pengajuanLemburDataTable.find(periode =>
        periode.tahun === parseInt(this.newPengajuanLembur.tahun) && periode.bulan.toLowerCase() === this.newPengajuanLembur.bulan.toLowerCase(),
      ))
      return isExist
    },
    handleOkModalManagePengajuanLembur() {
      const content = `${this.status.isEdit ? 'Apakah anda yakin ingin mengubah pengajuan Lembur ini? perubahan ini akan memperbaharui data yang lama' : 'Apakah anda yakin ingin membuat pengajuan Lembur baru ini? perubahan ini akan disimpan kedalam sistem'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Peringatan',
          description: 'Semua kolom wajib diisi',
        })
      } else {
        if (this.periodeIsExist() && this.status.isCreate) {
          return this.$notification.error({
            message: 'Gagal',
            description: 'Pengajuan Lembur yang anda buat sudah ada disistem. Gunakan Ubah untuk mengubah pengajuan Lembur',
          })
        }
        console.log(this.periodeIsExist())
        this.$confirm({
          title: 'Peringatan',
          content: (
            <div>{content}</div>
          ),
          onOk: async () => {
            const formData = new FormData()
            formData.append('id_pegawai', this.user.id)
            formData.append('waktu_awal', moment(this.newPengajuanLembur.waktu_awal).format('DD MMMM YYYY HH:mm'))
            formData.append('waktu_akhir', moment(this.newPengajuanLembur.waktu_akhir).format('DD MMMM YYYY HH:mm'))
            formData.append('keterangan_tambahan', this.newPengajuanLembur.keterangan_tambahan)
            if (this.newPengajuanLembur.files_upload.length) {
              this.newPengajuanLembur.files_upload.forEach(el => {
                formData.append('files_upload', el)
              })
            }
            try {
              this.loadingActionModalManagePengajuanLembur = true
              await axios[this.status.isEdit ? 'put' : 'post'](`${config.apiUrl}/api/pengajuan-lembur/pengajuan_lembur_by_pegawai${this.status.isEdit ? `/${this.idEdit}` : ''}`, formData, {
                // method: this.status.isEdit ? 'put' : 'post',
                headers: {
                  token: localStorage.token,
                },
              })
              this.loadingActionModalManagePengajuanLembur = false
              this.toggleModalManageLembur()
              this.fetchDataPengajuanLembur()
              const description = this.status.isEdit ? 'Pengajuan Lembur berhasil diubah' : 'Pengajuan Lembur baru berhasil ditambahkan'
              this.$notification.success({
                message: 'Berhasil',
                description,
              })
            } catch (error) {
              console.log(error)
              const description = this.status.isEdit ? 'Pengajuan Lembur gagal diubah' : 'Pengajuan Lembur baru gagal ditambahkan'
              this.$notification.error({
                message: 'Gagal',
                description,
              })
            }
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Ubah Sekarang' : 'Tambah Sekarang',
          cancelText: 'Batal',
        })
      }
    },
    handleDeletePengajuanLembur(data) {
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div>Apakah anda yakin ingin menghapus pengajuan Lembur <b>{data.keterangan_tambahan}</b> ? perubahan ini akan menghapus data disistem</div>
        ),
        onOk: () => {
          this.pengajuanLemburLoadingTable = true
          this.loadingDeletePengajuanLembur = true
          this.$store.dispatch('absensiKaryawan/DELETE_PENGAJUAN_LEMBUR_PEGAWAI', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataPengajuanLembur()
              this.loadingDeletePengajuanLembur = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Berhasil',
                  description:
                    'Pengajuan Lembur berhasil dihapus',
                })
              } else {
                this.$notification.error({
                  message: 'Gagal',
                  description: 'Pengajuan Lembur gagal dihapus',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeletePengajuanLembur = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Hapus',
        cancelText: 'Batal',
      })
    },
    fetchDataPengajuanLembur(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.pengajuanLemburLoadingTable = true
      this.$store.dispatch('absensiKaryawan/FETCH_PENGAJUAN_LEMBUR', { idPegawai: this.user.id, page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          this.pengajuanLemburLoadingTable = false
          // const pagination = { ...this.pagination }
          // pagination.total = res.total
          // this.pagination = pagination
          // console.log(res)
          this.pengajuanLemburDataTable = res.map(el => {
            return {
              key: el.id,
              ...el,
              waktu_awal: moment(el.waktu_awal).format('DD MMM YYYY HH:mm'),
              waktu_akhir: moment(el.waktu_akhir).format('DD MMM YYYY HH:mm'),
            }
          })
        })
    },
  },
  mounted() {
    this.fetchDataPengajuanLembur()
  },
  computed: {
    user() { return this.$store.state.user.user },
    isValidateForm() {
      if (this.newPengajuanLembur.waktu_awal && this.newPengajuanLembur.waktu_akhir) {
        return false
      }
      return true
    },
  },
}
</script>

<style>

</style>
